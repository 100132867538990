import { CustomFieldWithClientsDTO, CustomFieldDTO } from '@library/dto/workflow/custom-fields.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { ICustomFieldSearchParams } from '@one/app/shared/services/custom-fields/custom-fields.service.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService {
  private readonly url: string = `${SERVER_API_URL}api/custom-fields`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public searchCustomFields$(params: ICustomFieldSearchParams = {}): Observable<CustomFieldDTO[]> {
    return this._httpClient
      .get<CustomFieldDTO[]>(`${this.url}`, { params: { ...params } })
      .pipe(map((res): CustomFieldDTO[] => sortBy(res, 'order')));
  }

  public getClientsCustomFields$(): Observable<CustomFieldDTO[]> {
    return this._httpClient.get<CustomFieldDTO[]>(`${this.url}/clients`).pipe(map((res): CustomFieldDTO[] => sortBy(res, 'field.order')));
  }

  // Settings page

  public getCustomFields$(): Observable<CustomFieldWithClientsDTO[]> {
    return this._httpClient
      .get<CustomFieldWithClientsDTO[]>(`${this.url}/settings`)
      .pipe(map((res): CustomFieldWithClientsDTO[] => sortBy(res, 'field.order')));
  }

  public addCustomField$(field: Omit<CustomFieldDTO, 'id' | 'domainId'>): Observable<CustomFieldWithClientsDTO> {
    return this._httpClient.post<CustomFieldWithClientsDTO>(`${this.url}`, { field, clients: [] });
  }

  public updateCustomField$(customFieldWithClients: CustomFieldWithClientsDTO): Observable<CustomFieldWithClientsDTO> {
    return this._httpClient
      .put<CustomFieldWithClientsDTO>(`${this.url}/${customFieldWithClients.field.domainId}`, customFieldWithClients)
      .pipe(map((res): CustomFieldWithClientsDTO => ({ ...res, isUsed: customFieldWithClients.isUsed })));
  }

  public getCustomFieldValues$(domainId: string): Observable<CustomFieldWithClientsDTO[]> {
    return this._httpClient
      .get<CustomFieldWithClientsDTO[] | null>(`${this.url}/${domainId}/values`)
      .pipe(map((res): CustomFieldWithClientsDTO[] => res ?? []));
  }
}
